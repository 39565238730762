import { useQuery } from '@apollo/client';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@phoenix7dev/shared-components';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { config } from '../../config';
import { FeatureTypes, GameMode, IBonus, bonusIds } from '../../global.d';
import { setBonuses, setCurrency, setSlotConfig } from '../../gql/cache';
import { ISlotHistory, ISlotHistoryData } from '../../gql/d';
import { slotHistoryGql } from '../../gql/query';
import { isBaseReelSet, normalizeCoins, showCurrency } from '../../utils';
import { countCoins, isBuyFeatureReelSet } from '../../utils/helper';

import styles from './history.module.scss';
import LeftSvg from './leftSvg';
import RightSvg from './rightSvg';
import SkipLeftSvg from './skipLeftSvg';
import SkipRightSvg from './skipRightSvg';

const formatHistory = (historyData: ISlotHistoryData['edges']) => {
  const history = _.map(
    _.map(historyData, (elem) => ({
      ...elem.node,
      cursor: elem.cursor,
    })),
    (elem) => {
      const date = _.get(elem, 'createdAt', Date.now());
      let coins;
      if (isBaseReelSet(elem.reelSetId)) {
        coins = countCoins({
          coinValue: _.get(elem, 'coinValue', 1),
          coinAmount: _.get(elem, 'coinAmount', 1),
        });
      } else if (isBuyFeatureReelSet(elem.reelSetId)) {
        const bonuses = setBonuses();
        const bonus = _.chain(bonuses)
          .filter((bonus) => bonus.type === FeatureTypes.SPECIAL_ROUND && bonus.id === bonusIds[GameMode.BUY_FEATURE])
          .get(0, {})
          .value() as IBonus;
        coins =
          countCoins({
            coinValue: _.get(elem, 'coinValue', 1),
            coinAmount: _.get(elem, 'coinAmount', 1),
          }) * bonus.coinAmount;
      } else {
        coins = 0;
      }
      const bet = formatNumber({ currency: setCurrency(), value: coins, showCurrency: showCurrency(setCurrency()) });
      const win = formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(elem.result.winCoinAmount, _.get(elem, 'coinValue', 1)),
        showCurrency: showCurrency(setCurrency()),
      });

      return {
        date,
        win,
        bet,
        gameId: elem.id,
        cursor: elem.cursor,
      };
    },
  );

  return _.reverse(history);
};

const renderKey = (item: ISlotHistory, key: string) => {
  if (key === 'date') {
    return new Date(item[key as keyof ISlotHistory] as string).toLocaleString('en-US', { hour12: true });
  }

  if (key === 'bet') {
    return item[key as string];
  }

  return item[key as string];
};
const HistoryComponent: React.FC = () => {
  const { head, showBy } = config.historyTable;
  const { t } = useTranslation();
  const [pageAmount, setPageAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const cursorLastRef = useRef('');
  const cursorFirstRef = useRef('');

  const { data: historyData, fetchMore } = useQuery<
    { bets: ISlotHistoryData },
    {
      input: {
        first?: number;
        last?: number;
        before?: string;
        after?: string;
        filter?: { slotId?: string };
      };
    }
  >(slotHistoryGql, {
    variables: {
      input: { last: showBy, filter: { slotId: setSlotConfig().id } },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (historyData?.bets?.edges.length) {
      setPageAmount(Math.ceil(_.get(historyData, 'bets.pageInfo.count', 0) / showBy));
      cursorLastRef.current = historyData.bets.edges[historyData.bets.edges.length - 1]!.cursor;
      cursorFirstRef.current = historyData.bets.edges[0]!.cursor;
    }
  }, [historyData, showBy]);

  if (!historyData?.bets) {
    return (
      <div className={styles['history']}>
        <div className={styles['spinner']} />
      </div>
    );
  }

  const { pageInfo, edges } = historyData.bets;
  return (
    <div className={styles['history']}>
      <div className={styles['container']}>
        {formatHistory(edges).map((item) => (
          <div key={item.gameId} className={styles['history-item']}>
            {Object.keys(head).map((key, _index) => (
              <div key={key} className={`${styles['history-item__col']} ${styles[key as string]}`}>
                {t(key)}
                <div className={styles['value']}>{renderKey(item, key)}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {pageInfo.count > showBy && (
        <div className={styles['pagination']}>
          <Button
            type="button"
            disabled={currentPage === 1}
            className={styles['pagination__btn'] as string}
            key="first"
            onClick={() => {
              fetchMore({
                variables: {
                  input: {
                    last: showBy,
                    filter: { slotId: setSlotConfig().id },
                  },
                },
              }).then(() => {
                setCurrentPage(1);
              });
            }}
          >
            <SkipLeftSvg />
          </Button>
          <Button
            disabled={currentPage === 1}
            className={`${styles['pagination__btn']}`}
            onClick={() => {
              fetchMore({
                variables: {
                  input: {
                    first: showBy,
                    after: cursorLastRef.current,
                    filter: { slotId: setSlotConfig().id },
                  },
                },
              }).then(() => {
                setCurrentPage(Math.max(1, currentPage - 1));
              });
            }}
          >
            <LeftSvg />
          </Button>
          <Button
            disabled={currentPage === pageAmount}
            className={`${styles['pagination__btn']}`}
            onClick={() => {
              fetchMore({
                variables: {
                  input: {
                    last: showBy,
                    before: cursorFirstRef.current,
                    filter: { slotId: setSlotConfig().id },
                  },
                },
              }).then(() => {
                setCurrentPage(Math.min(pageAmount, currentPage + 1));
              });
            }}
          >
            <RightSvg />
          </Button>
          <Button
            disabled={currentPage === pageAmount}
            className={styles['pagination__btn'] as string}
            onClick={() => {
              fetchMore({
                variables: {
                  input: {
                    first: ((pageInfo.count - 1) % showBy) + 1,
                    filter: { slotId: setSlotConfig().id },
                  },
                },
              }).then(() => {
                setCurrentPage(pageAmount);
              });
            }}
          >
            <SkipRightSvg />
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(HistoryComponent);
