import { useMutation, useQuery } from '@apollo/client';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { Environments } from '@phoenix7dev/audio-api/dist/d';
import { Loader } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import {
  LOADER_MAPPED_SYMBOLS,
  LOADER_SPRITE_TEXTURES,
  LOADER_TEXTURES,
  SPINE_LOADER_TEXTURES,
  audioSprite,
  audioSpriteVolume,
} from '../../config';
import {
  BonusStatus,
  EventTypes,
  GameMode,
  IAuthInput,
  IBonus,
  IUserBalance,
  UserBonus,
  bonusIds,
  reelSets,
} from '../../global.d';
import {
  ReelSetType,
  authGql,
  client,
  configGql,
  getBonuses,
  getProgressGql,
  getSlotGql,
  getUserBonuses,
  getUserGql,
  setBetAmount,
  setBonuses,
  setBrokenBuyFeature,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setGameMode,
  setIsAuthorized,
  setIsBuyFeaturePopupOpened,
  setIsMobile,
  setIsSoundOn,
  setIsTurboSpin,
  setPreLoadedGrantBreakdown,
  setProgress,
  setReelSetId,
  setSlotConfig,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
  slotHistoryGql,
  userBonusBetsGql,
} from '../../gql';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import { ISlotData } from '../../slotMachine/d';
import { findSubstituteCoinAmount, loadErrorHandler, loadPixiAssets, parseQuery, wait } from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const getCoinValue = (slotConfig: ISlotData) => {
  if (setBrokenGame() || setBrokenBuyFeature()) {
    return setCurrentBonus().coinValue;
  }
  return slotConfig.clientSettings.coinValues.find((e) => e.code === setCurrency())?.variants[0];
};

const getCoinAmount = (slotConfig: ISlotData) => {
  if (setBrokenGame() || setBrokenBuyFeature()) {
    return setCurrentBonus().coinAmount;
  }
  const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
  const coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slotConfig.clientSettings.coinAmounts.default);

  return coinAmount;
};

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent, setShowContent] = useState(true);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setUserBalance(userBalance.data.user);
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0].node);
      }

      if (setUserLastBetResult().result.reelPositions.length === 0) {
        const lastBetRes = { ...setUserLastBetResult() };
        lastBetRes.reelSetId = reelSets[GameMode.REGULAR]!;
        lastBetRes.result = {
          ...lastBetRes.result,
          reelPositions: [0, 0, 0, 0, 0],
        };
        setUserLastBetResult(lastBetRes);
      }
    };
    const getPurchasableBonuses = async () => {
      const bonusData = await client.query<{ bonuses: IBonus[] }>({
        query: getBonuses,
        variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      setBonuses(bonusData.data.bonuses);
    };
    const checkBrokenGame = async () => {
      const activeUserBonusData = await client.query<{
        userBonuses: UserBonus[];
      }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });

      if (activeUserBonusData.data.userBonuses.length > 0) {
        setPreLoadedGrantBreakdown(activeUserBonusData.data.userBonuses[0]!.data.preLoadedGrantBreakdown);
        if (activeUserBonusData.data.userBonuses[0]!.bonusId === bonusIds[GameMode.BUY_FEATURE]) {
          setBrokenBuyFeature(true);
          setIsBuyFeaturePopupOpened(true);
          setCurrentBonus({
            ...activeUserBonusData.data.userBonuses[0]!,
            isActive: true,
            currentRound: 0,
          });
          setCurrentBonusId(activeUserBonusData.data.userBonuses[0]!.id);
        } else {
          setBrokenGame(true);
          setCurrentBonus({
            ...activeUserBonusData.data.userBonuses[0]!,
            isActive: true,
            currentRound: 0,
          });
          setCurrentFreeSpinsTotalWin(0);
          setCurrentBonusId(setCurrentBonus().id);
          const userBonusBetsData = await client.query<{
            bets: ISlotHistoryData;
          }>({
            query: userBonusBetsGql,

            variables: {
              input: {
                filter: {
                  userBonusId: setCurrentBonus().id,
                },
              },
            },
            fetchPolicy: 'network-only',
          });
          setCurrentBonus({
            ...setCurrentBonus(),
            currentRound: userBonusBetsData.data.bets.pageInfo.count,
            rounds: setCurrentBonus().rounds + userBonusBetsData.data.bets.pageInfo.count,
            totalWinAmount: 0,
          });
        }
      }

      if (setUserLastBetResult().reelSetId !== setCurrentBonus().reelSetId) {
        if (setCurrentBonus().reelSetId === undefined) {
          setCurrentBonus({
            ...setCurrentBonus(),
            reelSetId: setUserLastBetResult().reelSetId,
          });
        }
      }
    };
    const getSlotData = async () => {
      const slotData = await client.query<{ slot: ISlotData }>({
        query: getSlotGql,
        variables: { input: { id: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      const { slot } = slotData.data;

      const lines = slot.lines.map((_, index) => index);
      setSlotConfig({
        ...setSlotConfig(),
        clientSettings: slot.clientSettings,
        icons: slot.icons,
        reels: slot.reels,
        winLines: slot.lines,
        lines,
        lineSet: slot.lineSets[0]!,
        settings: slot.settings,
      });
      console.info(slot);
      console.info(setSlotConfig());

      setReelSetId(
        slot.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)?.id || reelSets[GameMode.REGULAR],
      );
      setGameMode(GameMode.REGULAR);

      const coinValue = getCoinValue(slot);
      const coinAmount = getCoinAmount(slot);
      setCoinValue(coinValue);
      setCoinAmount(coinAmount);

      setWinAmount(setUserLastBetResult().result.winCoinAmount);
      setBetAmount(coinAmount * slot.lineSets[0]!.coinAmountMultiplier);
    };

    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(10, ELoaderStages.AUTH, async (stage) => {
        setIsMobile(isMobile);
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });

        window.remoteStorage = remoteStorage;
        await remoteStorage.init(data?.auth.sessionId as string);
        rebuildStorageCache<IConfig>('config', {
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(30, ELoaderStages.BONUS_GAME, async (stage) => {
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBrokenGame();
        await getSlotData();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets(
          [
            ...LOADER_MAPPED_SYMBOLS,
            ...LOADER_SPRITE_TEXTURES,
            ...LOADER_TEXTURES,
            ...SPINE_LOADER_TEXTURES(setIsMobile()),
          ],
          process.env.PUBLIC_URL,
        );
        setProgress({
          ...setProgress(),
          status: stage,
        });
        await wait(500);
      })
      .stage(60, ELoaderStages.IMAGES, async (stage) => {
        setProgress({
          ...setProgress(),
          status: stage,
        });
        await wait(500);
      })
      .stage(80, ELoaderStages.AUDIO, async (stage) => {
        AudioApi.initialize({
          audioSprite,
          audioVolume: audioSpriteVolume,
          restricted: false,
          isSoundEnabled: isSoundOn,
          onSuspended: () => {},
          audioBaseUrl: `${process.env.PUBLIC_URL}/sound`,
          environment: window.__ENV__?.ENV ?? Environments.DEVELOPMENT,
        }).then(() => {
          eventManager.emit(EventTypes.SOUND_INITIALIZED);
        });
        setProgress({
          ...setProgress(),
          status: stage,
        });
        await wait(500);
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: false,
          });
          setTimeout(() => {
            setShowContent(false);
          }, 500);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles['loadScreenWrapper']}>
      <div className={styles['logo']}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles['companyLogo']}
        />
        <div className={styles['companyLogo_loaded']} style={{ height: `${progress?.status}%` }}>
          <img draggable="false" alt="logoLoaded" src={Resources.getSource(ResourceTypes.logo)} />
        </div>
      </div>
    </div>
  );
};

export default LoadScreen;
